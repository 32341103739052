import { useEffect, useContext} from "react"
import { StateContext } from "./context/Context";
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { Box} from '@mui/material';

function App() {

  const [state, setState] = useContext(StateContext)
 
  /*
  useEffect(()=>{
    const cache = localStorage.getItem('state')
    if (cache) {
      setState(JSON.parse(cache))
    } 
  },[])  
  */

  return (
    <Box>
      {state.token.length>0? <Dashboard state={state} setState={setState}/> : <Login state={state} setState={setState}/>}
    </Box>
  );
}

export default App;
