import { Box, Tooltip, Stack, Typography } from "@mui/material"
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
var path = process.env.PUBLIC_URL

function Contact() {
  return (

    <Box sx={{mt:1,height:'90px',width:'100%'}}>
      <Box sx={{borderTop:2,width:"90%",borderColor:'lightgray',ml:'5%',mt:2}}></Box>
      <Stack direction={'row'} justifyContent={'center'} mt={2}>
        <AlternateEmailIcon sx={{fontSize:24, pt:'6px',pr:1}}/>
        <Typography sx={{fontSize:24}}> Contact </Typography>         
      </Stack>
      <Stack direction='row' spacing={2} justifyContent={'center'} mt={1}>
            <Tooltip title="L'Oréal">
            <Typography
                noWrap
                component="a"
                href="https://www.loreal.com/"
                target="_blank"
                paddingRight={2}
                >
                <img style={{height:"24px",paddingTop:'10px'}} src={path+"/loreal.png"} alt="L'Oreal"/>
                </Typography>
            </Tooltip>                         
            <Tooltip title='Ceebios'>
            <Typography
                noWrap
                component="a"
                href="https://ceebios.com/"
                target="_blank"
                >
                <img style={{height:"46px"}} src={path+"/ceebios.png"} alt="Ceebios"/>
                </Typography> 
            </Tooltip>
        </Stack>
    </Box>
  )
}

export default Contact