import { Box, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { handleSearch } from './Cypher';

const topics = [
    {
        topic:"Care",
        image:"https://images.unsplash.com/photo-1648150303718-8089a3800235?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to skin care",
    },
    {
        topic:"Cleansing",
        image:"https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to cleansing",
    },
    {
        topic:"Coloration",
        image:"https://images.unsplash.com/photo-1623474237394-055b5c5b9095?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to colouration",
    },
    {
        topic:"Make-up",
        image:"https://images.unsplash.com/photo-1584389789168-70153f08cbd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to make-up",
    },
    {
        topic:"Packaging",
        image:"https://images.unsplash.com/photo-1542936221-b02434e3d36e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to packaging",
    },
    {
        topic:"Protect from the sun",
        image:"https://images.unsplash.com/photo-1573551701016-d173344231b0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to UV protection",
    },        
    {
        topic:"Sensoriality",
        image:"https://images.unsplash.com/photo-1565769583321-29e69b493031?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to sensoriality",
    },        
    {
        topic:"Water management",
        image:"https://plus.unsplash.com/premium_photo-1675705062445-0c14a42d4289?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640",
        desc:"Explore biological models from projects related to water management",
    },        
]


const Topics = ({state, setState}) => {
    const navigate = useNavigate()

    const Card = (topic) => {
        const size = "240px"
        const [over, setOver] = useState(false)
        const handleClick = (e,topic)=>{
            e.preventDefault()
            const newstate = {...state, topic:topic}
            setState(newstate)
            handleSearch(e,newstate,setState)
            navigate("/search")
        }

        return (
            <Box key={topic.topic} 
            onClick={(e)=>handleClick(e,topic.topic)}
            onMouseOver={(e)=>{e.preventDefault(); setOver(true)}}
            onMouseOut={(e)=>{e.preventDefault(); setOver(false)}}
            sx={{
                width:size,height:size,m:0.5, position:'relative', cursor:'pointer',
                borderRadius:2,
                overflow:"hidden",
                "&:hover":{
                    transform:"scale(1.03)"
                }
            }}>
                <Box sx={{
                display: 'block',
                width: size,
                height:size,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${topic.image})`,
                backgroundSize:'cover',
                position:'absolute',            
                }}>
                    <Box sx={{p:0,background:"linear-gradient(0deg, rgba(255,255,255,0.0), rgba(255,255,255,0.9),rgba(255,255,255,0.0));", width:"100%", height:"100%",
                    }}>
                        <Box sx={{p:0.5, textAlign:'center'}}>                        
                            {!over?
                            <Typography sx={{fontSize:22, fontWeight:'bold', mt:13}}>{topic.topic}</Typography>
                            :<Typography sx={{fontSize:14, mt:12}}>{topic.desc}</Typography>
                            }
                            
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
    
    return (
    <Box sx={{ml:6,mr:6,mt:2, display:'flex', flexWrap:"wrap"}}>
        {topics.map(topic=>Card(topic))}
    </Box>
  )
}

export default Topics