import { Box, Stack, Typography } from "@mui/material"
import PDF from "./PDF"

const PanelFulltext = ({ state, setState }) => {
  console.log(state.molecule)
  return (
    <Box>
      {
        (state.molecule !== undefined) ?
          <Stack direction='row' sx={{ display: 'flex', flexWrap: 'wrap', mb: 1 }} spacing={1}>
            {state.molecule.map(mol => (
              <Box sx={{ border: '1px dotted', borderColor: 'rgba(20,20,20,0.75)', p: 0.5 }}>
                <Typography sx={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: 'small' }}>{mol.name}</Typography>
                {mol.cas.length > 0 ? <Typography sx={{ fontSize: 'small' }}>CAS: {mol.cas}</Typography> : <></>}
                {mol.reaxys.length > 0 ? <Typography sx={{ fontSize: 'small' }}>Reaxys: {mol.reaxys}</Typography> : <></>}
                {mol.chebi.length > 0 ? <a href={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${mol.chebi}`} target='_blank' rel="noreferrer"><Typography sx={{ fontSize: 'small' }}>CHEBI: {mol.chebi}</Typography></a> : <></>}
                {mol.wikidata.length > 0 ? <a href={`https://www.wikidata.org/wiki/${mol.wikidata}`} target='_blank' rel="noreferrer"><Typography sx={{ fontSize: 'small' }}>Wikidata: {mol.wikidata}</Typography></a> : <></>}
              </Box>))}
          </Stack>
          : <></>
      }
      <Stack sx={{
        overflowY: 'auto', height: `600px`, boxShadow: 0,
        scrollbarColor: "rgba(46,54,69,0.5) rgba(210,210,210,0.5)",
        scrollbarWidth: "thin",
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.50)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.5)',
          outline: '0px solid slategrey',
        }
      }} spacing={1}>
        {
          state.panel.pdfname !== undefined ? <PDF filename={`etudes/${state.panel.pdfname}`.normalize("NFC")} state={state} setState={setState} height={'580px'} /> : <></>
        }
      </Stack>
    </Box >
  )
}

export default PanelFulltext