import { Box, Tooltip } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { GraphContext, StateContext } from '../context/Context'
import Cytoscape from "cytoscape";
import COSEBilkent from 'cytoscape-cose-bilkent';
import CytoscapeComponent from 'react-cytoscapejs';
import dagre from 'cytoscape-dagre';
import { palette } from './PDF';
import { fetchGraph, fetchCypher } from './Cypher';
import AllOutIcon from '@mui/icons-material/AllOut';

Cytoscape.use(COSEBilkent);
Cytoscape.use(dagre);

const layout = {
  name: 'cose-bilkent', animate: true, nodeDimensionsIncludeLabels: true
}

const Graph = ({ mode, setMode }) => {
  const [graph, setGraph] = useContext(GraphContext)
  const [state, setState] = useContext(StateContext)
  const [selected, setSelected] = useState()
  const [cy, setCy] = useState()
  const [visible, setVisible] = useState("hidden")
  const [over, setOver] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })


  const formatGraphData = (graph) => {
    let nodes = []
    let edges = []
    var isselected = false
    Object.entries(graph.nodes).forEach(([k, v]) => {
      if (v.properties.id === state.panel.id) {
        isselected = true
      } else {
        isselected = false
      }
      nodes.push({ classes: "multiline-auto", selected: isselected, data: { neo_id: v.properties.id, label: v.properties.name, id: Number(k), type: v.label } })
    })
    Object.entries(graph.edges).forEach(([k, v]) => {
      if (v.type === 'HAS_CHILD') {
        edges.push({ data: { source: Number(v.start), target: Number(v.end), label: 'SUB_STRATEGY', id: k } })
      } else {
        edges.push({ data: { source: Number(v.start), target: Number(v.end), label: v.type, id: k } })
      }

    })
    return nodes.concat(edges)
  }

  useEffect(() => {
    if (cy !== undefined) {
      cy.layout(layout).run()
    }
  }, [graph])

  useEffect(() => {
    if (selected) {
      const nid = selected.data().neo_id
      const item = state.results.filter(n => n.id === nid)[0]
      if (item !== undefined) {
        setState({ ...state, panel: item })
      } else {
        let query = `match (e:Etude)--(m:Model {id:"${nid}"})--(s:Strategy)`
        query = query + ` RETURN distinct {
          label:labels(m)[0],
          pdfs:m.pdfs,
          id:m.id,
          name:m.name,
          year:e.year,
          etude:e.name,
          contact_client:e.contact_client,
          contact_ceebios:e.contact_ceebios,
          strategy:s.name
      } as node`
        console.log(query)
        fetchCypher(query, state).then(res => res.json())
          .then(res => {
            if (res.length > 0) {
              setState({ ...state, panel: res[0] })
            }
          })

      }
    }

  }, [selected])


  const handleExpand = () => {
    if (over) {
      const query = `match (n)-[r]-(node) where ID(n)=${over.id()} and labels(node)[0] in ['Strategy','Model'] return r,node`
      fetchGraph(query, state).then(res => res.json())
        .then(res => {
          const nodes = { ...graph.nodes, ...res.nodes }
          const edges = { ...graph.edges, ...res.edges }
          setGraph({ nodes: nodes, edges: edges })
        })
    }
  }

  const style = [
    {
      selector: 'node',
      style: {
        width: 30,
        height: 30,
        'background-color': node => palette[node.data('type')],
        label: 'data(label)',
        'font-size': 10,
        'border-width': 0,
        "text-wrap": "wrap",
        "text-max-width": 80,
        "color": node => node.data('neo_id') === state.panel.id ? 'red' : "black",
      }
    },
    {
      selector: 'edge',
      style: {
        'width': 1,
        'line-color': 'grey',
        'target-arrow-color': 'grey',
        'target-arrow-shape': 'triangle',
        'curve-style': 'bezier',
        'font-size': 8,
        label: 'data(label)',
        "text-rotation": "autorotate",
        "text-background-color": "white",
        "text-background-opacity": 1,
      }
    },
    {
      selector: ':selected',
      style: {
        'border-width': 2,
      }
    },
  ]

  const handleOver = (e) => {
    const pos = e.target.renderedPosition()

    let zoom = 1
    if (cy !== undefined) {
      zoom = cy.zoom()
    }
    const offset = 8 * zoom
    setPosition({ x: pos.x + offset, y: pos.y + offset })
    setVisible("visible")
    setOver(e.target)
  }

  const handleLeave = (e) => {
    setVisible("hidden")
    setOver(null)
  }

  return (
    <Box sx={{ flex: 1, height: '800px', position: 'relative' }} id='graphcontainer'>
      <CytoscapeComponent
        elements={formatGraphData(graph)}
        style={{ width: "100%", height: `750px`, backgroundColor: 'white' }}
        stylesheet={style}
        minZoom={0.5}
        maxZoom={2}
        layout={layout}
        panningEnabled={true}
        userZoomingEnabled={true}
        cy={cy => {
          setCy(cy);
          cy.on('click', 'node', function (evt) {
            setSelected(evt.target);
          });
          cy.on('mouseover', 'node', function (evt) {
            handleOver(evt)
          });
          cy.on('mouseout', 'node', function (evt) {
            handleLeave(evt)
          });
        }}
      />
      <Tooltip title="Expand">
        <AllOutIcon
          sx={{
            visibility: visible, position: "absolute", top: position.y, left: position.x, cursor: "pointer", borderRadius: 5, backgroundColor: "whitesmoke",
            "&:hover": { boxShadow: 2, color: "rgba(250,40,40,0.9)" }
          }}
          onMouseOver={(e) => { e.preventDefault(); setVisible("visible") }}
          onMouseLeave={(e) => { e.preventDefault(); if (!over) setVisible("hidden") }}
          onClick={handleExpand}
        />
      </Tooltip>
    </Box>
  )
}

export default Graph