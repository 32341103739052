import { Box, Typography, Stack, Tooltip } from "@mui/material"
import { pink, green } from "@mui/material/colors"
import { fetchGraph, fetchCypher } from "./Cypher"
import { useContext, useState } from "react"
import { GraphContext } from "../context/Context"

export const palette = {
    'Etude': 'rgb(241,102,103)',
    'Job': 'rgb(76,142,218)',
    'Model': 'rgb(141,204,147)',
    'Strategy': 'rgb(201,144,192)',
    'Slide': 'rgb(247,151,103)',
}

export const hoverpalette = {
    'Etude': 'rgb(201,62,63)',
    'Job': 'rgb(36,102,178)',
    'Model': 'rgb(101,164,107)',
    'Strategy': 'rgb(161,104,152)',
    'Slide': 'rgb(207,111,63)',
}

export const definitions = {
    Model: "Biological system: potential source of inspiration",
    Etude: "Project that first described the biological model",
    Strategy: "How does the model performs the investigated function?",
    "C. Function": "Cosmetic function categories",
    Slide: "Powerpoint slides for the biological model"
}

const style = {
    fontSize: 'small',
    textDecoration: 'underline',
    color: green[700],
    cursor: 'pointer',
    "&:hover": {
        color: green[900]
    }
}


const Results = ({ state, setState, mode, setMode }) => {
    const [graph, setGraph] = useContext(GraphContext)

    const handleModel = (e, item) => {
        e.preventDefault()
        handleGraph(e, item, false)
    }

    const handleGraph = (e, item, swithmode) => {
        e.preventDefault()
        setState({ ...state, panel: item })
        const query = `match (:Strategy {name:"${item.strategy}"})-[r*0..2]-(node) where labels(node)[0] in ['Strategy','Model'] return r,node`

        fetchGraph(query, state)
            .then(res => res.json())
            .then(res => { setGraph(res) })
        if (swithmode) {
            setMode(1 - mode)
        }
    }

    const renderModel = (i, item) => {
        let width = 0
        if (item.id === state.panel.id) {
            width = 1
        }

        const handleExpertise = () => {
            console.log('Hi')
            setState({ ...state, panel: { ...state.panel, mode: 'expertise' } })
        }

        const getActions = () => {
            const q = `${item.name} ${item.strategy}`
            return (
                <>
                    <Stack direction={'row'} spacing={0.5}>
                        <a target='_blank' rel="noreferrer" href={encodeURI(`https://scholar.google.com/scholar?hl=en&q=${q.replaceAll(' ', '+')}`)}><Typography sx={style}>Publications</Typography></a>
                        {/*<a target='_blank' rel="noreferrer" href={encodeURI(`https://biomig-search.com/?query=${q.replaceAll(' ', '+')}`)}><Typography sx={style}>Publications</Typography></a>*/}
                        <Typography sx={{ fontSize: 'small', color: style.color }}>‧</Typography>
                        <Typography sx={style} onClick={handleExpertise}>Expertises</Typography>
                        <Typography sx={{ fontSize: 'small', color: style.color }}>‧</Typography>
                        <a href={`mailto:${item.contact_client}@loreal.com`} target="_blank" rel="noreferrer"><Typography sx={style}>Get project updates</Typography></a>
                        <Typography sx={{ fontSize: 'small', color: style.color }}>‧</Typography>
                        <a href={`mailto:${item.contact_ceebios}@ceebios.com`} target="_blank" rel="noreferrer"><Typography sx={style}>Request in-depth analysis</Typography></a>
                    </Stack>
                </>
            )
        }

        return (
            <Box key={i} sx={{ borderWidth: width, borderStyle: 'dotted', pl: 0.5, pr: 0.5, borderRadius: 1 }}>
                <Box>
                    <Typography onClick={(e) => handleModel(e, item)}
                        sx={{
                            cursor: 'pointer', textDecoration: 'underline', fontSize: 'medium', color: green[700], fontWeight: 'bold',
                            "&:hover": {
                                color: green[900]
                            }
                        }}
                    >{item.name}</Typography>
                    <Stack direction={'row'} spacing={2}>
                        <Typography fontSize='small' color='text.secondary'> Project: {item.etude}</Typography>
                        <Typography fontSize='small' color='text.secondary'> Year: {item.year}</Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                        <Tooltip title={definitions.Strategy}>
                            <Typography fontSize='small' color='text.secondary' >Idea derived from model:</Typography>
                        </Tooltip>
                        <Typography sx={{ ...style, color: pink[700] }} onClick={(e) => handleGraph(e, item, true)} fontStyle={'italic'}>{item.strategy}</Typography>
                    </Stack>
                    {getActions()}
                </Box>
            </Box>
        )
    }

    const renderPage = (i, item) => {
        let width = 0
        if (item.pdfid === state.panel.pdfid) {
            width = 1
        }

        const handlePage = () => {
            const ids = item.moleculeid.map(m => `'${m}'`).join(',')
            const query = `MATCH (node:Molecule) where node.id in [${ids}] RETURN node`
            fetchCypher(query, state)
                .then(res => res.json())
                .then(res => setState({ ...state, molecule: res, panel: item }))
        }


        return (
            <Box key={i} sx={{ borderWidth: width, borderStyle: 'dotted', pl: 0.5, pr: 0.5, borderRadius: 1 }}>
                <Box>
                    <Typography onClick={handlePage}
                        sx={{
                            cursor: 'pointer', textDecoration: 'underline', fontSize: 'medium', color: green[700], fontWeight: 'bold',
                            "&:hover": {
                                color: green[900]
                            }
                        }}
                    >{item.pdftitle}</Typography>
                    <Typography fontSize='small' color='text.secondary'> Project: {item.etudename}</Typography>
                    <Stack direction={'row'} spacing={0.5}>
                        <Typography fontSize='small' color='text.secondary'> Molecules:</Typography>
                        <Stack useFlexGap flexWrap="wrap" direction={'row'} spacing={0.5} divider={<Typography variant='span' sx={{ fontSize: 'large' }}>‧</Typography>}>
                            {
                                item.moleculename.map((mol, j) => (<Typography sx={{ fontSize: 'small', color: 'text.secondary' }}> {mol}</Typography>))
                            }
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} spacing={0.5}>
                        <Typography fontSize='small' color='text.secondary'> Models:</Typography>
                        <Stack useFlexGap flexWrap="wrap" direction={'row'} spacing={0.5} divider={<Typography variant='span' sx={{ fontSize: 'large' }}>‧</Typography>}>
                            {
                                item.modelname.map((mol, j) => (<Typography sx={{ fontSize: 'small', color: 'text.secondary' }}> {mol}</Typography>))
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        )
    }

    const items = state.results.slice(state.pagenumber * state.pagesize, (state.pagenumber + 1) * state.pagesize)
    return (
        <Stack spacing={2} sx={{ flex: 1 }}>
            {items.map((item, i) => {
                if (state.fulltext) {
                    return renderPage(i, item)
                } else {
                    return renderModel(i, item)
                }
            })}
        </Stack>

    )
}

export default Results