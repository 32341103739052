import { Box, Stack, Divider } from "@mui/material"
import Contact from "./Contact"
import Methodo from "./Methodo"
import Topics from "./Topics"

const Landing = ({state, setState}) => {
  return (
    <Stack height="calc(100vh - 200px)" justifyContent={'space-between'}>
      <Stack direction={'column'} spacing={2}>
          <Topics state={state} setState={setState}/>
          <Methodo/>
      </Stack>
      <Contact/>
    </Stack>
  )
}

export default Landing