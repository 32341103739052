import { Box, Typography, Stack } from '@mui/material';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';

const About = () => {  
    return (
    <Box sx={{width:'90%', ml:'5%'}}>
      <Stack direction={'row'} justifyContent={'center'} mt={2}>
        <EmojiNatureIcon sx={{fontSize:24, pt:'6px',pr:1}}/>
        <Typography sx={{fontSize:24}}> About L'Oréal Biomim Database </Typography>         
      </Stack>
      <Typography m={1} color={'text.secondary'} fontSize={16}>
        L'Oréal has been one of the most advanced players in Biomimicry in France.
      </Typography>        
      <Typography m={1} color={'text.secondary'} fontSize={16}>
        In partnership with Ceebios, more than 20 R&D projects have been carried out using this multidisciplinary approach,  
        which is inspired by living organisms to invent more eco-responsible innovations.
      </Typography>                            
      <Typography m={1} color={'text.secondary'} fontSize={16}>
        This tool will help you navigate and explore the knowledge generated during these projects.
      </Typography>                            
    </Box>
    );
  }

export default About