import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StateProvider, GraphProvider } from './context/Context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateProvider>
      <GraphProvider>
        <App/>
      </GraphProvider>
    </StateProvider>    
  </React.StrictMode>
);

