import { Box, Menu, MenuItem, IconButton, Stack, Typography, FormControlLabel, Checkbox } from "@mui/material"
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useState } from "react";
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import background from '../images/navbar.jpg'
import Searchbar from "./Searchbar"
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import About from "./About";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Filters from "./Filters";


function Fulltext({ state, setState }) {
    const handleClick = () => {
        setState({ ...state, fulltext: !state.fulltext, results: [], maxpage: 0 })
    }

    return (
        <Tooltip title="Search in full">
            <FormControlLabel
                sx={{ m: 0, p: 0 }}
                control={
                    <Checkbox checked={state.fulltext} onClick={handleClick} size="small" sx={{
                        p: 0, m: 0,
                        color: "white",
                        '&.Mui-checked': {
                            color: "white",
                        },
                    }} />
                }
                label={
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'white', "&:hover": { color: 'rgba(255,255,255,0.8)' } }}>Full text</Typography>
                } />
        </Tooltip>

    )

}

const Navbar = ({ state, setState }) => {
    const [anchor, setanchor] = useState(null);
    const [about, setAbout] = useState(false);
    const navigate = useNavigate()


    const handleClick = (e, target) => {
        e.preventDefault()
        navigate(target)
    }

    const handleMenu = (event, source) => {
        setanchor(event.currentTarget);
    };

    const handleClose = () => {
        setanchor(null);
    };

    const handleLogout = () => {
        var new_state = { ...state }
        new_state.token = ""
        localStorage.setItem('state', JSON.stringify(new_state));
        setState(new_state)
    }

    const handleAbout = () => {
        setAbout(true)
    }

    const handleAboutClose = () => {
        setAbout(false)
    }


    return (
        <Box sx={{ boxShadow: 1, backgroundColor: 'rgb(230,230,230)', position: 'fixed', zIndex: 100, top: 0, width: '100%', left: 0 }}>
            <Modal
                open={about}
                onClose={handleAboutClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <About />
                </Box>
            </Modal>
            <Box sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            }}>
                <img src={background} alt='Leaf' style={{
                    objectPosition: "bottom",
                    objectFit: "cover",
                    width: '100%',
                    height: '100%'
                }} />
            </Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2}>
                    <Tooltip title='Home'>
                        <IconButton onClick={(e) => handleClick(e, "/")}>
                            <HomeIcon sx={{ fontSize: 32, color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>

                <Box sx={{ width: "40%", position: 'relative' }}>
                    <Searchbar state={state} setState={setState} />
                    <Stack direction={'row'} alignItems={'center'} position={'relative'} spacing={2} justifyContent={'end'}>
                        <Fulltext state={state} setState={setState} />
                        <Filters state={state} setState={setState} />
                    </Stack>
                </Box>

                <Stack direction={'row'} spacing={2}>
                    <Tooltip title='Results list'>
                        <IconButton onClick={(e) => handleClick(e, "/search")}>
                            <FormatListNumberedIcon sx={{ fontSize: 32, color: 'white' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Saved models'>
                        <IconButton aria-label="cart" onClick={(e) => handleClick(e, "/cart")}>
                            <Badge badgeContent={state.shoppingcart.length} color="secondary" >
                                <ShoppingCartIcon sx={{ fontSize: 32, color: 'white' }} />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Account'>
                        <>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-account"
                                aria-haspopup="true"
                                onClick={(e) => handleMenu(e, 'account')}>
                                <AccountCircle sx={{ fontSize: 32, color: 'white' }} />
                            </IconButton>
                            <Menu
                                id="menu-account"
                                anchorEl={anchor}
                                keepMounted
                                open={Boolean(anchor)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleAbout}>About</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                <MenuItem onClick={console.log(state)}>State</MenuItem>
                            </Menu>
                        </>
                    </Tooltip>
                </Stack>

            </Stack>
        </Box>
    )
}

export default Navbar