import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import { handleSearch } from "./Cypher";
import { useNavigate,useLocation } from "react-router-dom";
import {Box} from "@mui/material";
import { blue } from "@mui/material/colors";

const Searchbar = ({state, setState}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const search = (e)=>{
        handleSearch(e,state,setState)
        if (location.pathname!=='/search') {
            navigate('/search')
        }
    }

    return (
        <Box>
            <form onSubmit={(e)=>search(e)} 
            style={{
                width:"100%", 
                backgroundColor:"white",
                borderRadius:4,
                marginTop:5
            }}
            >
                <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                    <TextField
                    value={state.search}
                    //multiline
                    sx={{width:"100%"}}
                    id="search-bar"
                    onInput={(e) => {
                        let newstate = {...state}
                        newstate.search = e.target.value
                        setState(newstate);
                    }}
                    //label="Search"
                    variant="outlined"
                    placeholder="Search ..."
                    size="small"
                    />       
                    <IconButton size='small' type="submit" aria-label="search">
                        <SearchIcon style={{ fill: blue[600] }} />
                    </IconButton>                      
                </Stack>
            </form>
        </Box>


    )
}
  
export default Searchbar