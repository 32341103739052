import { Box, Stack, Button } from "@mui/material"
import PDF from "./PDF"
import { fetchCypher } from "./Cypher";

const Panel = ({ state, setState }) => {

  function handleSave() {
    let new_state = { ...state }
    const data = { ...new_state.panel, search: state.search, topic: state.topic }

    const query =
      `match (:${state.panel.label} {id:"${state.panel.id}"})--(node) return distinct {label:labels(node)[0],id:node.id,name:node.name} as node`
    fetchCypher(query, state)
      .then(res => res.json())
      .then(res => {
        const card = res.filter(el => el.label === 'Card')
        if (card.length > 0) {
          data.card = card[0].name
        }
        const conc = res.filter(el => el.label === 'Concept')
        if (conc.length > 0) {
          data.concept = conc[0].name
        }
        const strategies = res.filter(el => el.label === 'Strategy').map(el => el.name)
        data.strategies = strategies.join(',')
        new_state.shoppingcart.push(data)
        setState(new_state)
      }
      )
  }

  let pdfs = []
  console.log(state.panel)
  if ((state.panel.mode !== undefined) && (state.panel.experts !== undefined) && (state.panel.experts.length>0)) {
    pdfs = state.panel.experts.split("\n")
  } else {
    if (state.panel.pdf !== undefined) {
      pdfs = [state.panel.pdf]
    }
  }
  console.log(pdfs)

  return (
    <Box>
      <Stack sx={{
        overflowY: 'auto', height: `600px`, boxShadow: 0,
        scrollbarColor: "rgba(46,54,69,0.5) rgba(210,210,210,0.5)",
        scrollbarWidth: "thin",
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.50)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.5)',
          outline: '0px solid slategrey',
        }
      }} spacing={1}>
        {
          pdfs.map(el => <PDF key={el} filename={`etudes/${el}`.normalize("NFC")} state={state} setState={setState} height={'580px'} />)
        }
      </Stack>
      {pdfs.length > 0 ?
        <Stack direction={'row'} spacing={2} justifyContent={'space-evenly'}>
          <Button variant="contained" onClick={handleSave}>Save Model</Button>
        </Stack>
        : <></>}
    </Box>
  )
}

export default Panel