import { uri } from "./Config"
import { init_state } from "../context/Context";

export const fetchGraph = (query, state) => {
    const options = {
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${state.token}`
        },
        body: JSON.stringify({ text: query })
    }

    return fetch(`${uri}/graph`, options)
}

export const fetchExpand = (query, state) => {
    const options = {
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${state.token}`
        },
        body: JSON.stringify({ text: query })
    }

    return fetch(`${uri}/expand`, options)
}

export const fetchCypher = (query, state) => {
    const options = {
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${state.token}`
        },
        body: JSON.stringify({ text: query })
    }

    return fetch(`${uri}/neo4j`, options)
}

export const handleQuery = (query, state, setState, handleResults) => {
    const options = {
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${state.token}`
        },
        body: JSON.stringify({ text: query })
    }

    fetch(`${uri}/neo4j`, options)
        .then(res => {
            if (res.ok) {
                return res.json()
            }
            console.log(res)
            throw new Error(res.statusText)
        })
        .then(data => handleResults(data, state, setState))
        .catch((error) => {
            alert('Session expired. Please login again.')
            var new_state = { ...init_state }
            new_state.token = ""
            localStorage.setItem('state', JSON.stringify(new_state));
            setState(new_state)
        }
        )
}


const handleResults = (res, state, setState) => {

    var results = {}
    res.forEach(el => {
        results[el.id] = el
    });
    results = Object.values(results)

    setState({ ...state, maxpage: results.length, pagenumber: 0, results: results, advanced: false, panel: {} });
}


export const handleSearch = (e, state, setState) => {
    e.preventDefault()
    const load = {
        term: state.search,
        filter: '',
        fulltext: state.fulltext
    }
    if ((state.topic !== null) && (state.topic !== undefined) && (state.topic.length > 0)) {
        load.filter = state.topic
    }
    console.log(load)
    if ((load.term.length + load.filter.length) === 0) {
        setState({ ...state, loading: false, maxpage: 0, pagenumber: 0, results: [], advanced: false, panel: {}, molecule: [] })
    } else {
        const options = {
            method: 'POST',
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${state.token}`
            },
            body: JSON.stringify(load)
        }
        fetch(`${uri}/search`, options)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                console.log(res)
                throw new Error(res.statusText)
            })
            .then(data => {
                if (load.fulltext) {
                    console.log(data)
                    setState({ ...state, results: data, maxpage: data.length, molecule: [] })
                } else {
                    handleResults(data, state, setState)
                }
            }

            )
            .catch((error) => {
                alert('Session expired. Please login again.')
                var new_state = { ...init_state }
                new_state.token = ""
                localStorage.setItem('state', JSON.stringify(new_state));
                setState(new_state)
            }
            )
    }
}
