import { Paper } from "@mui/material"

export const palette = {
    'Etude': 'rgb(241,102,103)',
    'Job': 'rgb(76,142,218)',
    'Model': 'rgb(141,204,147)',
    'Strategy': 'rgb(201,144,192)',
    'PDF': 'rgb(247,151,103)',
}

export const hoverpalette = {
    'Etude': 'rgb(201,62,63)',
    'Job': 'rgb(36,102,178)',
    'Model': 'rgb(101,164,107)',
    'Strategy': 'rgb(161,104,152)',
    'PDF': 'rgb(207,111,63)',
}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const PDF = ({ filename, state, setState, height }) => {
    let fname = filename.split("/")
    let page = 0

    if (isNumeric(fname[fname.length - 1])) {
        page = fname[fname.length - 1]
        fname = `${fname.slice(0, fname.length - 1).join("/")}.pdf`.replace('#', '')
    } else if (filename.length > 0) {
        fname = filename
        page = 1
    }

    return (
        <Paper key={fname} width='100%'>
            {page ?
                <embed
                    title="PDF"
                    src={`${fname}#page=${page}&view=fitH`}
                    height={height}
                    width={`${window.innerWidth * 0.45}px`}
                    type="application/pdf"
                ></embed>
                : <div>
                    PDF not available
                </div>}
        </Paper>
    )
}

PDF.defaultProps = {
    state: {},
    setState: {}
};


export default PDF

