import { useState, createContext } from "react";

export const StateContext = createContext();
export const init_state = {
    token: "",
    pagesize: 10,
    pagenumber: 0,
    maxpage: 0,
    waiting: false,
    search: "",
    fulltext: false,
    topic: "",
    topics: [],
    results: [],
    help: false,
    focus: {},
    panel: {},
    shoppingcart: [],
    molecule: []
}


export const StateProvider = (props) => {
    const [state, setState] = useState(init_state);
    return (
        <StateContext.Provider value={[state, setState]}>
            {props.children}
        </StateContext.Provider>
    );
}


export const GraphContext = createContext();
export const GraphProvider = (props) => {
    const [graph, setGraph] = useState(
        {
            nodes: {},
            edges: {},
        }
    );
    return (
        <GraphContext.Provider value={[graph, setGraph]}>
            {props.children}
        </GraphContext.Provider>
    );
}
