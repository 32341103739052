import { Box, Pagination, Stack, Typography, Button } from "@mui/material"
import Panel from "./Panel";
import Graph from "./Graph";
import { useState } from "react";
import { steps } from "./BiomimSteps";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import { iconsPassive, iconsActive } from "./BiomimSteps";
import Results from "./Results";
import PanelFulltext from "./PanelFulltext";

const Feed = ({ state, setState }) => {
  const [mode, setMode] = useState(0)

  const handleChangePage = (event, newPage) => {
    setState({ ...state, pagenumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, pagesize: parseInt(event.target.value, 10), pagenumber: 0 });
  };

  const handleMode = () => {
    setMode(1 - mode)
  }


  return (
    <Box position='relative' m={1}>
      <Box mb={1}>
        <Stepper activeStep={1} orientation="horizontal">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIcon {...props} icon={index <= 1 ? iconsActive[index] : iconsPassive[index]} />
                )}
              >
                {index === 1 ? <Typography variant='span' sx={{ textDecoration: 'underline' }}>{step.label}</Typography> : <Typography variant='span'>{step.label}</Typography>}
              </StepLabel>
            </Step>
          )
          )}
        </Stepper>
      </Box>
      {state.results.length > 0 ?
        <Box>
          <Stack direction='row' sx={{ display: 'flex', width: '100%' }}>
            <Box flex={1} sx={{ mr: 2 }}>
              <Stack direction='row' sx={{ height: '20px', mb: 1, width: '100%' }}>
                <Button disabled={mode === 0} variant={mode === 0 ? 'contained' : 'text'} onClick={handleMode} sx={{ flex: 1, textAlign: 'center' }}>Search Mode</Button>
                <Button disabled={mode === 1} variant={mode === 1 ? 'contained' : 'text'} onClick={handleMode} sx={{ flex: 1, textAlign: 'center' }}>Graph Mode</Button>
              </Stack>
              <Stack spacing={2} minHeight={'800px'} alignItems="top" direction={'row'} sx={{ width: '100%' }}>
                {mode === 0
                  ? <Results state={state} setState={setState} mode={mode} setMode={setMode} />
                  : <Graph mode={mode} setMode={setMode} />
                }
              </Stack>
            </Box>
            <Box flex={1} sx={{ position: 'relative' }}>
              <Box>
                {
                  state.fulltext ? <PanelFulltext state={state} setState={setState} /> : <Panel state={state} setState={setState} />
                }
              </Box>
            </Box>
          </Stack>
          <Box sx={{ ml: 'calc(50% - 200px)', width: '410px', mt: 1 }}>
            {mode === 0 ?
              <Pagination
                color="secondary"
                count={Math.ceil(state.maxpage / state.pagesize)}
                page={state.pagenumber}
                onChange={handleChangePage}
              />
              : <></>
            }
          </Box>
        </Box>
        : <></>}
    </Box>
  )
}

export default Feed