import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIcon } from '@mui/material';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import {palette} from './PDF'
import {GiButterflyFlower} from 'react-icons/gi'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TransformIcon from '@mui/icons-material/Transform';

import {yellow, green, orange,lightBlue,indigo} from "@mui/material/colors";

const colors = [yellow[600], orange[900],green[600],indigo[700],lightBlue[600]]
export const iconsActive = [
  <AccountTreeIcon sx={{fill:colors[0]}}/>,
  <GiButterflyFlower fill={colors[1]}/>,
  <TravelExploreIcon sx={{fill:colors[2]}}/>,
  <TipsAndUpdatesIcon sx={{fill:colors[3]}}/>,
  <TransformIcon sx={{fill:colors[4]}}/>,  
]
export const iconsPassive = [
  <AccountTreeIcon sx={{fill:'grey'}}/>  ,
  <GiButterflyFlower fill='grey'/>  ,
  <TravelExploreIcon sx={{fill:'grey'}}/>  ,
  <TipsAndUpdatesIcon sx={{fill:'grey'}}/>  ,
  <TransformIcon sx={{fill:'grey'}}/>  
]


const textStyle = {fontSize:16, color:'text.secondary'}

export const steps = [
  {
    label: 'Functional problem analysis',
    description: [
      <Typography sx={textStyle}>The first step for a biomimetician is to perform the functional problem analysis:</Typography>,
      <ul>
        <li><Typography sx={textStyle}>What function are we trying to solve for?</Typography></li>
        <li><Typography sx={textStyle}>What are the constraints? </Typography></li>
      </ul>,
      <Typography sx={textStyle}>Output: Generate keywords and directives to perform a biology search.</Typography>,
    ]
  },
  {
    label: 'Explore biology',
    description:[
      <Typography sx={textStyle}>With given keywords explore biology databases and bibliography to find relevant models that perform your function or have evolved facing the same constraints.</Typography>,
      <Typography sx={textStyle}>This tool will help you explore the knowledge generated during the various biomimetic projects done in collaboration between Ceebios and L'Oréal.</Typography>,
      <Typography mt={1} sx={textStyle}>The search engine is keyword-based but you can also filter by biomimetic study (Etude) or cosmetic function.</Typography>,
      <Typography sx={textStyle}>The result of a search is an interactive list of the most relevant biological models as well as related data.</Typography>,
      <Typography mt={1} sx={textStyle}>Please see below for defitions:</Typography>,
      <ul>
        <li><Typography variant='span' sx={{color:palette['Model'],fontSize:18,fontWeight:'bold'}}>Biological model: </Typography><Typography variant='span' sx={{fontSize:18, color:'text.secondary'}}>Biological entity which could be the source of inspiration for a biomimicry-lead innovation.</Typography></li>
        <li><Typography variant='span' sx={{color:palette['Etude'],fontSize:18,fontWeight:'bold'}}>Etude: </Typography><Typography variant='span' sx={{fontSize:18, color:'text.secondary'}}>A project Ceebios has done for L'Oréal that first described a given model.</Typography></li>
        <li><Typography variant='span' sx={{color:palette['Strategy'],fontSize:18,fontWeight:'bold'}}>Strategy: </Typography><Typography variant='span' sx={{fontSize:18, color:'text.secondary'}}>The strategy describes how a model performs given biological function.</Typography></li>
        <li><Typography variant='span' sx={{color:palette['Job'],fontSize:18,fontWeight:'bold'}}>Cosmetic Function: </Typography><Typography variant='span' sx={{fontSize:18, color:'text.secondary'}}>Cosmetic function categories as seen by L'Oréal.</Typography></li>
        <li><Typography variant='span' sx={{color:palette['Slide'],fontSize:18,fontWeight:'bold'}}>Slide: </Typography><Typography variant='span' sx={{fontSize:18, color:'text.secondary'}}>A power-point slide used as visual support to describe given biological model.</Typography></li>
      </ul>

    ]
  },
  {
    label: 'In-depth study of models',
    description:[
      <Typography sx={textStyle}>Select a few models with high innovative potential, perform functional abstraction and define the technical principles behind achieving the desired functional properties.</Typography>,
      <Typography mt={1} sx={textStyle}>This tool will provide acces to technical abstractions where available.</Typography>,
      <Typography mt={1} sx={textStyle}>For more in depth analysis you can request new technical abstractions to be done by the Ceebios team.</Typography>,
    ],
  },
  {
    label: 'Concept prototype',
    description:[
      <Typography sx={textStyle}>This stage is dedicated to selecting one or two functional abstractions identified in the previous step and designing technical concepts.</Typography>,
      <Typography mt={1} sx={textStyle}>This tool will provide links to concepts already explored by L'Oréal and contacts to relevant teams.</Typography>,
    ],
  },
  {
    label: 'Market',
    description:[
      <Typography sx={textStyle}>The final step is to deploy a new product to the market.</Typography>,
      <Typography variant='h6' sx={{mt:3, color:blue[800],textAlign:'center',width:'100%'}}>To start explorating nature solutions go to the top-level search bar.</Typography>
    ],
  },

];

export default function BiomimSteps() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{width:'90%', mt:2, ml:'5%'}}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((step, index) => (
            <Step key={step.label}>
            <StepLabel 
                StepIconComponent={(props) => (
                  <StepIcon {...props} icon={index<=activeStep?iconsActive[index]:iconsPassive[index]} />
                )}                
              >
              {index===activeStep?<Typography variant='span' sx={{textDecoration:'underline'}}>{step.label}</Typography>:<Typography variant='span'>{step.label}</Typography>}
            </StepLabel>
          </Step>
        )
        )}
      </Stepper>
      <div>
      <Button
        disabled={activeStep === steps.length-1} variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
          Next
        </Button>
        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button color='secondary' onClick={handleReset} sx={{ mt: 1, mr: 1,visibility:activeStep<steps.length-1?'hidden':'visible'}}>
            Reset
        </Button>        
      </div>    
      <Box sx={{mt:2}} textAlign='left'>
          {steps[activeStep].description}
      </Box>  
    </Box>
  );
}