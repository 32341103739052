import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from "@mui/icons-material/Search";


const Help = ({state, setState}) => {

  const getHelp = (page) => {
    if (page==='pdf') {
      return (
      <>
        <DialogTitle id="alert-dialog-title">
          {"Search mode help"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            In this mode the interface will search through the text in the PDFs.
            <p>Type keywords in the search bar and then click on the search button <SearchIcon style={{ fill: "blue" }}/>.</p>
            <p> Answers can be filtered by Etude (Project) and/or relevant Cosmetic function via the two drop-down lists in the side bar. </p>
            <p>The results can also be narrowed down to only slides containing Biological Models via the switch button at the top of the side bar.</p>
          </DialogContentText>
        </DialogContent>      
      </>)
    } else if (page==='question') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">
            {"Explore mode help"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              In this mode the interface will query directly the graph.
              <p>
                Choose a category in the panel on the left and click on Submit to see results.                
                Explore connections in the graph by expanding the results as a folder structure.
                Click on PDF of a Model to see it's corresponding slide.
              </p>
              <p> 
                To narrow down result expand the 'Advanced' section and select a category.
                Narrow down further by filtering on elements of the graph in the Dropdown filter component.
              </p>
            </DialogContentText>
          </DialogContent>      
        </>)  
    } else {
      return (
        <>
          <DialogTitle id="alert-dialog-title">
            {"Graph mode help"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            The graph mode is under construction.
            <p>We will place tools in the side bar to help construct graph queries. </p>
            <p>The output graph will be shown in the main area.</p>
            <p>We will also implement some level of ineractivity, which is yet to be defined. </p>
            </DialogContentText>
          </DialogContent>      
        </>)  
    }
  }
  
  return (
      <div>
      <Dialog
        open={state.help}
        onClose={(e)=>{e.preventDefault();setState({...state, help:false})}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {getHelp(state.tab)}
      </Dialog>
    </div>
    );
  }

export default Help