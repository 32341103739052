import { Box, Typography, Stack, Divider, Button } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import { iconsPassive, iconsActive } from "./BiomimSteps";
import { steps } from "./BiomimSteps";
import PDF from "./PDF"

const Cart = ({ state, setState }) => {
    const [mode, setMode] = useState(0)
    const [activeStep, setActiveStep] = useState(2)

    function handleRemove(e, item) {
        e.preventDefault()
        let new_state = { ...state }
        new_state.shoppingcart = state.shoppingcart.filter(el => el.id !== item.id)
        setState(new_state)
    }

    function handleMode(e, i) {
        e.preventDefault()
        setMode(i)
        if (i === 2) {
            setActiveStep(3)
        } else {
            setActiveStep(2)
        }
    }

    function getPdfContent(item) {
        if (mode === 0) {
            let pdfs = []
            if (item.pdfs !== undefined) {
                pdfs = item.pdfs.split("\n")
            }
            console.log(pdfs)
            return pdfs.map(el => <PDF key={el} filename={`etudes/${el}`.normalize("NFC")} state={state} setState={setState} height='480px' />)
        }
        if (mode === 1) {
            if (item.card !== undefined) {
                return <PDF filename={`cards/${item.card}`.normalize("NFC")} state={state} setState={setState} height='400px' />
            } else {
                return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}><Button onClick={(e) => { e.preventDefault(); alert('Please contact Ceebios representative.') }}>Request model in-depth analysis</Button></Box>
            }
        }
        if (mode === 2) {
            if (item.concept !== undefined) {
                return <PDF filename={`cards/${item.concept}`.normalize("NFC")} state={state} setState={setState} height='400px' />
            } else {
                return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}><Button onClick={(e) => { e.preventDefault(); alert("Please contact L'Oréal representative.") }}>Request concept generation</Button></Box>
            }
        }
        return <></>
    }

    const getItem = (item) => {
        console.log(item)
        return (
            <Stack direction={'row'} spacing={2} mt={2}>
                <Box flex={1}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Model:</TableCell>
                                    <TableCell align="right">{item.name}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Strategy:</TableCell>
                                    <TableCell align="right">{item.strategies}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Search term:</TableCell>
                                    <TableCell align="right">{item.search}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Contact L'Oréal:</TableCell>
                                    <TableCell align="right">{item.contact_client}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Contact Ceebios:</TableCell>
                                    <TableCell align="right">{item.contact_ceebios}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Etude:</TableCell>
                                    <TableCell align="right">{item.etude}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Summary:</TableCell>
                                    <TableCell align="right">{item.summary}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Year:</TableCell>
                                    <TableCell align="right">{item.year}</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">Topic filter:</TableCell>
                                    <TableCell align="right">{item.topic}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button color='error' onClick={(e) => handleRemove(e, item)}>Remove Item</Button>
                    </Box>
                </Box>
                <Stack flex={1}>
                    <Box>
                        {getPdfContent(item)}
                    </Box>
                </Stack>
            </Stack>
        )
    }

    return (
        <Box height={"calc(100vh = 100px)"}>
            <Box m={1}>
                <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                StepIconComponent={(props) => (
                                    <StepIcon {...props} icon={index <= activeStep ? iconsActive[index] : iconsPassive[index]} />
                                )}
                            >
                                {index === activeStep ? <Typography variant='span' sx={{ textDecoration: 'underline' }}>{step.label}</Typography> : <Typography variant='span'>{step.label}</Typography>}
                            </StepLabel>
                        </Step>
                    )
                    )}
                </Stepper>
            </Box>
            <Stack spacing={2} sx={{ overflowY: 'auto' }} divider={<Divider orientation="horizontal" />}>
                <Stack direction={'row'} justifyContent={'center'} sx={{ width: '50%', ml: '50%', borderBottom: "1px dashed blue" }}>
                    <Button onClick={(e) => handleMode(e, 0)} disabled={mode === 0}>Slides</Button>
                    <Button onClick={(e) => handleMode(e, 1)} disabled={mode === 1}>Technical Abstractions</Button>
                    <Button onClick={(e) => handleMode(e, 2)} disabled={mode === 2}>Concepts</Button>
                </Stack>
                {state.shoppingcart.map(item => getItem(item))}
            </Stack>
        </Box>
    )
}

export default Cart