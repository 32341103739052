import { Box, Typography, Stack } from "@mui/material"
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BiomimSteps from "./BiomimSteps";

const Methodo = () => {
  return (
    <Box textAlign={'center'} flex={1}>
        <Stack direction={'row'} justifyContent={'center'} mt={2}>
          <AccountTreeIcon sx={{fontSize:24, pt:'6px',pr:1}}/>
          <Typography sx={{fontSize:24}}> Biomimicry methodology </Typography>         
        </Stack>      
        <BiomimSteps/>
    </Box>
  )
}

export default Methodo