import { Box, Stack, Typography } from "@mui/material"
import { handleQuery } from "./Cypher"
import { useEffect, useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const Filters = ({ state, setState }) => {
  const [open, setOpen] = useState(false)

  const handleRes = (res) => {
    const topics = res.filter(r => r.label === 'Topic').map(el => el.name)
    setState({ ...state, loading: false, topics: topics.sort() });
  }

  useEffect(() => {
    const query = `match (node) where (node:Topic) return {id:node.id,name:node.name, label:labels(node)[0]} as node`
    handleQuery(query, state, setState, handleRes)
  }, [])


  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };


  const alltopics = ['Any'].concat(state.topics)


  const handleFilter = (event, top) => {
    event.preventDefault()
    if (top === 'Any') {
      setState({ ...state, topic: "" });
      handleClick()
    } else {
      setState({ ...state, topic: top });
      handleClick()
    }
  };

  return (
    <Box sx={{ textAlign: 'right', position: 'relative' }}>
      <Box onClick={handleClick} sx={{
        color: 'white',
        fontSize: 14, fontWeight: 'bold', fontFamily: 'Arial',
        cursor: 'pointer',
        "&:hover": {
          color: 'rgba(255,255,255,0.8)'
        }
      }}>
        {
          state.topic === '' ?
            <Typography variant='span'>Topic Filter</Typography>
            : <Typography variant='span'>Topic: {state.topic}</Typography>
        }
        <Typography variant='span' fontSize={16}>▾</Typography>
      </Box>
      {
        open ?
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'absolute', backgroundColor: 'white', boxShadow: 2, right: 0, borderRadius: 1, minWidth: '180px' }}>
              {
                alltopics.map(top => (
                  <Stack direction={'row'} onClick={(e) => handleFilter(e, top)} sx={{
                    cursor: 'pointer',
                    pl: 1, pr: 1,
                    "&:hover": {
                      backgroundColor: 'rgb(180,180,180)'
                    }
                  }}>
                    <Box sx={{ width: '20px' }}>
                      {((top === state.topic) || ((top === 'Any') && (state.topic === ''))) ? <DoneIcon sx={{ fontSize: 15, transform: "translate(-2px,3px)" }} /> : <></>}
                    </Box>
                    <Box>
                      <Typography fontSize={14}>{top}</Typography>
                    </Box>
                  </Stack>
                ))
              }
            </Box>
          </ClickAwayListener>
          : <></>
      }
    </Box>
  )
}

export default Filters