import { Box, Grid, Typography, TextField, Button } from "@mui/material"
import { useState} from "react";
import { uri } from "./Config";

var path = process.env.PUBLIC_URL;

const Login = ({state, setState}) => {
    const [login, setlogin] = useState({
        user:'test',
        pass:'test'
    })

    const handleSubmit = () => {
        if ((login.user.length>0)&&(login.pass.length)>0) {
            const options = {
                method:'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    accept: 'application/json'
                },
                body:`grant_type=&username=${login.user}&password=${login.pass}&scope=&client_id=&client_secret=`,
            }
            
            fetch(`${uri}/token`, options)  
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                console.log(res)
                throw new Error(res.statusText)
            })
            .then(data => setState({...state, token:data.access_token}))
            .catch((error)=>alert(error))
        }
    }

    return (
        <Box  sx={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
            <Box>
                <Box width="1600px" pt={10} sx={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                    <img style={{height:"800px", borderTopLeftRadius:20, borderBottomLeftRadius:20}} src={path+"/rose.jpg"} alt="rose petal"/>
                    <img style={{height:"800px", borderTopLeftRadius:20, borderBottomLeftRadius:20, transform:"scaleX(-1)"}} src={path+"/rose.jpg"} alt="rose petal"/>
                </Box>
                <Box sx={{boxShadow:10,  position:"relative", top:-660,left:600,borderRadius:5, backgroundColor:"white", width:"400px", height:"480px"}}>
                    <Box sx={{ml:2, pt:1, width:"90%", display: 'flex', justifyContent:'space-between'}}>
                            <img style={{marginTop:"10px", height:"14px"}} src={path+"/loreal.png"} alt="L'Oreal"/>
                            <img style={{height:"36px"}} src={path+"/ceebios.png"} alt="Ceebios"/>
                    </Box>
                    <Typography mt={2} ml={2} mb={10} fontWeight="bold" fontSize={24} color="rgb(24,64,86)">
                        Welcome
                    </Typography>            
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box ml={2} mr={2}>
                            <TextField
                                sx={{width:"100%"}}                        
                                id="outlined-required"
                                label="E-mail"
                                type="text"
                                value={login.user}
                                onChange={(e)=>setlogin({...login, user:e.target.value})}
                                />                             
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml={2} mr={2}>
                                <TextField
                                sx={{width:"100%"}}   
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                value={login.pass}
                                onChange={(e)=>setlogin({...login, pass:e.target.value})}
                                />                        
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{pt:4, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                <Button onClick={(e)=>{e.preventDefault();handleSubmit()}} variant="contained" sx={{backgroundColor:"rgb(24,64,86)"}}>Login</Button>
                            </Box>                    
                        </Grid>                
                    </Grid>
                </Box>
            </Box>
        </Box>
  )
}

export default Login