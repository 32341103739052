import { Box, Stack } from "@mui/material"
import Navbar from "./Navbar"
import Help from "./Help"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./Landing";
import Feed from "./Feed";
import Cart from "./Cart";

const Dashboard = ({ state, setState }) => {

  return (
    <Box sx={{ mt: '55px', height: "calc(100vh - 100px)", backGroungColor: 'green', overflowY: 'auto' }}>
      <Router>
        <Navbar state={state} setState={setState} />
        <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', margin: "5px" }}>
          <Stack width="100%" p="0px 0px 0px 0px">
            <Routes>
              <Route path='/' element={<Landing state={state} setState={setState} />} />
              <Route path='/search' element={<Feed state={state} setState={setState} />} />
              <Route path='/cart' element={<Cart state={state} setState={setState} />} />
            </Routes>
          </Stack>
        </Box>
        <Help state={state} setState={setState} />
      </Router>
    </Box>
  )
}

export default Dashboard